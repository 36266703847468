import { Injectable, HostListener } from "@angular/core";
import { BehaviorSubject, Observable, Subscriber } from "rxjs";

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  perfil?: string[];
}

@Injectable({
  providedIn: "root",
})
export class NavService {
  public screenWidth: any;
  public collapseSidebar: boolean = false;
  public fullScreen = false;

  constructor() {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true;
    }
  }

  // Windows width
  @HostListener("window:resize", ["$event"])
  onResize(event?: any) {
    this.screenWidth = window.innerWidth;
  }

  MENUITEMS: Menu[] = [
    {
      path: "/atendimentos/listar",
      icon: "file-text",
      title: "Atendimentos",
      type: "link",
      perfil: ["99"]
    },
    // {
    //   path: "/atendimentos/cadastrados",
    //   icon: "file-text",
    //   title: "Atendimentos Cadastrados",
    //   type: "link",
    // },
    {
      title: "Cadastros Básicos",
      icon: "home",
      type: "sub",
      active: false,
      perfil: ["1", "3", "5", "6"],
      children: [
        {
          path: "/numerosuporte",
          icon: "help-circle",
          title: "Número Suporte",
          type: "link",
        },
        {
          path: "/cliente",
          icon: "help-circle",
          title: "Clientes",
          type: "link",
        },
        {
          path: "/solicitante",
          icon: "help-circle",
          title: "Solicitantes",
          type: "link",
        },
        {
          path: "/sistema",
          icon: "help-circle",
          title: "Sistemas",
          type: "link",
        },
        {
          path: "/rotina",
          icon: "help-circle",
          title: "Rotinas",
          type: "link",
        },
        {
          path: "/motivo",
          icon: "help-circle",
          title: "Motivos",
          type: "link",
        },
        {
          path: "/usuario",
          icon: "help-circle",
          title: "Usuários",
          type: "link",
        },
      ],
    },
    {
      title: "Relatórios",
      icon: "file-text",
      type: "sub",
      active: false,
      perfil: ["1", "3", "5"],
      children: [
        {
          path: "/relatorio/atendimento",
          icon: "help-circle",
          title: "Atendimento",
          type: "link",
        },
      ],
    },
    {
      title: "Financeiro",
      icon: "dollar-sign",
      type: "sub",
      active: false,
      perfil: ["1", "7"],
      children: [
        {
          path: "/gastos/listar",
          icon: "dollar-sign",
          title: "Gastos",
          type: "link",
        },
      ],
    },
  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
